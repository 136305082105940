<template>
  <div id="question">
    <TopBar class="background">
      <template #leftInfo>
        <div style="white-space: nowrap">
          <svg
            t="1601170867949"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="4264"
            width="24"
            height="24"
            @click="goLastPage"
          >
            <path
              d="M608.992 148.992L277.984 489.984q-8.992 8.992-8.992 21.504t8.992 22.496l331.008 340.992q8.992 8 20.992 8t20.992-8.992 8.992-20.992-8.992-20.992l-312-320 312-320q8.992-8.992 8.992-20.992t-8.992-20.992-20.992-8.992-20.992 8z"
              p-id="4265"
              fill="#ffffff"
            ></path>
          </svg></div
      ></template>
      <template #middleInfo>
        <span class="topTitle">历史记录说明</span>
      </template>
      <template #rightInfo> </template>
    </TopBar>
    <div class="questionWrap">
      <div class="type">
        <span class="h4"><br />已报价</span>指的是推送给您、您已报价的订单。
        报价后的订单会存在4种情况：
      </div>

      <p>1、等待指派 结果指的是该订单尚未指派师傅，还在选择中。</p>
      <p>2、已指派您 指的是该订单已指派您，可以开始服务了。</p>
      <p>
        3、已关闭
        指的是该订单已被商家关闭，可能存在不再需要服务或订单信息填写错误等原因。
      </p>
      <p>4、已指派其他师傅 指的是该订单已由其他师傅服务了。</p>
      <div class="type">
        <span class="h4">未报价已结束</span>
        指的是推送给您后，由于以下列出的因素导致您不能报价，不再在待报价列表显示的订单。
      </div>

      <p>
        1、已关闭<br />
        （1）订单被商家主动关闭，可能存在不再需要服务或订单信息填写错误等原因；<br />
        （2）订单的24小时报价倒计时已结束，但没有师傅报价，自动关闭。
      </p>
      <p>
        2、报价时间已截止 订单的24小时报价倒计时已结束，有师傅报价，但尚未指派。
      </p>
      <p>3、报价人数已满 指的是该订单已达到报价人数上限。</p>
      <p>4、已指派其他师傅 指的是该订单已由其他师傅服务了。</p>
      <p>
        5、信息修改超出服务范围<br />
        （1）下单方修改订单的服务类目、服务类型和服务地址；<br />
        （2）师傅个人修改服务类型和服务地址；
      </p>
      <p>
        以上修改导致订单不再在您的服务范围内，而不能再报价。
        因此，当您收到推送，却找不到新订单时，这类新订单可能是由于以上情况而没有显示在待报价列表里。
      </p>
    </div>
  </div>
</template>
<script>
import TopBar from "@/components/home/TopBar.vue";

export default {
  components: {
    TopBar,
  },
};
</script>
<style lang="less">
.questionWrap {
  background: #fff;
  padding: 1rem;
  margin-top: 20px;
  .type {
    text-indent: 0;
    font-size: 1.6rem;
    margin: 1.5rem 0;
    .h4 {
      font-weight: 600;
      margin-right: 1rem;
    }
  }
  p {
    text-indent: 1rem;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.8;
    letter-spacing: -0.3px;
  }
}
</style>